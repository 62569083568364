.header {
    text-align: center;
    padding: 20px 0;
    font-size: 40px;
}

.information{
    color: rgb(165, 185, 212);
    font-style: italic;
    padding-bottom: 20px;
    text-align: center;
    font-size: 20px;
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 28px;
        padding-bottom: 0;
    }

    .information{
        padding: 10px;
        font-size: 16px;
    }
}