.banner {
    max-width: 550px;
    width: 500px;
    height: 100px;
    position: relative;
    margin: 0 auto;
    div{
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        top: 12%;
        font-size: 34px;
    }
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index:-20;
    }
}

@media only screen and (max-width: 540px) {
    .banner {
        width: 250px;
        height: 50px;
        div{
            top: 8%;
            font-size: 20px;
        }
    }
}