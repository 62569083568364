button {
  background: #223b54;
  color: #909ba0;
  &:hover {
    // background: #03808c;
    opacity: 0.5;
    color: white;
  }
  border: none;
}

.steamNameInput {
  width: calc(100% - 52px);
}

.submit {
  margin-top: 20px;
  padding: 5px 40px;
}

.steamIcon {
  height: 40px;
  padding-right:12px;
}

.flagButton {
  text-align: center;
}

h2 {
  margin-top: 20px;
}

.container {
  text-align: center;
  color: white;
  margin-top: 80px;
  //override the existing min height
  min-height: 0;
  form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    .inputGroup {
      width: 500px;
      text-align: left;
      padding-bottom: 25px;
    }
  }
}

.banner {
  margin-bottom: 20px;
}
.steamUser {
  color: #91B3DF;
}
.error{
  color:red;
}

@media only screen and (max-width: 540px) {
  h2 {
    margin-top: 5px;
  }

  .container {
    margin-top: 40px;
    form {
      .inputGroup {
        width: 300px;
      }
    }
  }
}