.switchRegion {
  display: block;
  color: white;
  width: 75%;
  height: 50px;
  font-size: 22px;
  margin: 1vh auto;
  padding: 5px 5px;
  &.active {
    background: #a0c0e8;
    color: #486281;
  }
.switchRegion:disabled {
  background: #28333d;
  }
}

@media only screen and (max-width: 540px) {
  .switchRegion {
    font-size: 15px;
  }
}
