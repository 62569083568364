.wishlistButton{
  margin-top: 10px;
  width: 100%;
  display: block;
  background: #03808C;

  letter-spacing: 1px;
  color: white;
}

.wishlistButton button{
  text-align: center;
  width:100%;
  display: block;
}

.wishlistHeader {
  margin:0;
  padding:5px 0px;
  font-size: 24px;
}

.wishlistSubHeader {
  margin:0;
  padding-bottom: 5px;
  font-size: 16px;
}

@media only screen and (max-width: 540px) {
  .wishlistHeader {
    padding:5px 5px 5px 5px;
    letter-spacing: 1px;
    font-size: 24px;
  }
  .wishlistSubHeader {
    letter-spacing: 1px;
    font-size: 10px;
    padding:0 5px 5px 5px;
  }
}

