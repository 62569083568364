/* empties for now */

.header {
    font-size: 40px;
    padding-top: 20px;
    text-align: center;
}

/* copied into somewhere else, maybe we can make a component out of it */
.information{
    color: rgb(165, 185, 212);
    font-style: italic;
    padding: 20px;
    text-align: center;
    font-size: 20px;
}

.block {
    margin: 0 50px;
    margin-bottom: 25px;
    position: relative;
    text-align: center;
    padding-bottom: 23px;

}

.block.rblHasJoin {
    margin-bottom: 40px;
    padding-bottom: 0px;
}

.title {
    height: 25px;
    line-height: 25px;
}
 
.emblem {
    position: absolute;
    left: -10px;
    height: 75px;
}

.status {
    font-size: 25px;
    padding-top: 15px;
}

.reason {
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
    padding: 0 10px;
    padding-top: 10px;
}

.button {
    position: relative;
    height: 34px;
    width: 100%;
    line-height: 34px;
    transform: translateY(50%);
    cursor: pointer;
    transition: transform .03s;
}

.button:hover {
    transform:  translateY(50%) scale(1.2);
    filter:drop-shadow(0px 0px 10px rgba(255,255,255,0.1));
}

.button:click {
    filter: none
}

.joinBtnBg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    cursor: pointer;
    z-index: -1;
}


.backButton {
    background-color: rgb(17, 164, 154);
    width: 200px;
    text-align: center;
    padding: 10px;
    bottom: 50px;
    cursor: pointer;
    margin: 50px auto;
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 24px;
    }

    .objective {
        font-size: 16px;
    }

    .backButton {
        font-size: 16px;
        width: 120px;
        padding: 5px;
    }


    .information {
        font-size: 16px;
    }

    .block {
        margin: 0 25px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .title {
        font-size: 12px;
    }

    .emblem {
        height: 50px;
        left: -5px;
    }

    .status {
        font-size: 16px;
    }

    .reason {
        font-size: 13px;
    }

    // .button

    // .backButton

    // .joinBtnBg

    @media only screen and (max-width: 540px) {
        .block {
            margin: 0 25px;
            padding-bottom: 12px;
            margin-bottom: 12px;
        }

        .information{
            padding: 10px 15px 10px 15px;
            font-size: 15px;
        }

        .backButton {
            margin: 25px auto;
        }
    }
}