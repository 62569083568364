button {
  background: #223b54;
  color: #909ba0;
  &:hover {
    // background: #03808c;
    opacity: 0.5;
    color: white;
  }
  border: none;

  &.flagButton {
    min-width: 300px;
  }
}

@media only screen and (max-width: 540px) {
  button {
    &.flagButton {
      min-width: 230px;
    }
  }
}

.steamIcon {
  height: 80%;
}
.chooseVoteIcon {
  height: 80%;
  margin-right: 10px;
}

.pronoun {
  margin: 0 10px;
}

.submit {
  margin-top: 20px;
  padding: 5px 40px;
}

h2 {
  margin-top: 20px;
}

.container {
  text-align: center;
  color: white;
  margin-top: 80px;
  //override the existing min height
  min-height: 0;
  form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    .inputGroup {
      width: 500px;
      text-align: left;
    }
  }
}

.pronoun {
  background: #5a769a;
  color: #94a5c4;
  width: 29%;
  height: 50px;
  font-size: 22px;
  border-radius: 8px;
  &.active {
    background: #a0c0e8;
    color: #486281;
  }
}

.banner {
  margin-bottom: 20px;
}

.error {
  color: red;
}
