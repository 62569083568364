.pronoun {
  background: #5a769a;
  color: #94a5c4;
  width: 29%;
  height: 50px;
  font-size: 22px;
  border-radius: 8px;
  margin: 0 10px;
  padding: 5px 5px;
  
}

@media only screen and (max-width: 540px) {
  .pronoun {
    width: calc(33% - 4px);
    height: 30px;
    font-size: 15px;
    border-radius: 6px;
    margin: 0 2px;
    padding: 0;
  }
}
