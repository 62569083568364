.debugMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    z-index: 9999999;
}

@media only screen and (max-width: 540px) {
    .debugMenu {
        zoom: 0.8;
        bottom: 0px;
    }
}