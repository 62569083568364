.fillPage {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 100%;
    z-index: 1;
}

.box {
    position: absolute;
    width: 500px;
    background: #1B243A;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.xButton {
    position: absolute;
    background-color: black;
    top: -10px;
    right: -10px;
    border-radius: 20px;
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 0 10px;
    cursor: pointer;
}

.title {
    background-color: rgb(179, 30, 70);
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 20px 0;
}

.message {
    color: rgb(165, 185, 212);
    text-align: center;
    padding: 50px 0;
    font-style: italic;
}