.twitchLinkMain {
    background-color: #1b243a;
    background-image: url("background.png");
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.twitchLinkContainer {
    max-width: 100vh;
    padding: 3vh;
    background-color: rgba(20, 24, 44, 0.7);
    text-align: center;
}

.twitchLinkContainer h1 {
    color: white;
    font-family: 'Germania One', Veranda, cursive;
    font-size: 4vh;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
}

.twitchLinkContainer p {
    color: gainsboro;
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.7vh;
    margin-bottom: 1vh;
}

.twitchLinkContainer b {
    color: white;
}

.twitchLinkContainer a {
    color:white;
}

.twitchLinkContainer img{
    width: 80vh;
}
.twitchLinkContainer mark{
     background: none;
     color: #6441a5;
     padding: 0;
 }

.twitchLinkButton{
    border: none;
    height: 6vh;
    font-family: 'Germania One', Veranda, cursive;
    font-size: 3vh;
    cursor: pointer;
    background-color: #03808C;
    color: white;
    align-content: center;
    margin: 2vh auto;
    width: 60%;
    display: grid;
    justify-content: center;
}


.twitchLinkButton a {
    text-decoration: none;
    margin: 0 auto;
}

