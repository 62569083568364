.container {
    position: relative;
    height: 100%;
}

.header {
    font-size: 40px;
    padding-top: 20px;
    text-align: center;
}

/* copied into somewhere else, maybe we can make a component out of it */
.information{
    padding: 25px 0;
    text-align: center;
    font-size: 16px;
}

.separator {
    margin-top: 25px;
    margin-bottom: 50px;
}

.name {
    // background-color: #ad52e1;
    text-align: center;
    font-size: 30px;
    padding: 10px 0;
}

.objective {
    color: rgb(165, 185, 212);
    text-align: center;
    font-size: 20px;
    font-style: italic;
    text-align: right;
    margin: 10px 0;
    margin-right: 20px;
}

.current {
    text-align: right;
    margin-right: 20px;
    color: rgb(165, 185, 212);
    font-style: italic;
}

.button {
    background-color: rgb(17, 164, 154);
    width: 200px;
    text-align: center;
    padding: 10px;
    bottom: 50px;
    cursor: pointer;
    margin: 50px auto;
}

.button:hover {
    opacity: 0.5;
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 24px;
    }

    .name {
        font-size: 24px;
    }

    .objective {
        font-size: 16px;
    }

    .button {
        font-size: 14px;
        width: 120px;
        padding: 5px;
    }
}