.container {
    text-align: center;
    color:white;
    margin-top: 100px;
    //override the existing min height
    min-height: 0; 
}

.infoMessage {
    margin: 20px 0;
}

.gameCodeInput {
  margin: 20px 0;
  font-family: Noto, sans-serif;
}

.gameCodeInput::-webkit-input-placeholder {
  font-family: Germania, sans-serif;
}

.gameCodeInput::-moz-placeholder {
  font-family: Germania, sans-serif;
}
.gameCodeInput:-moz-placeholder {
  font-family: Germania, sans-serif;
}

.gameCodeInput::-ms-input-placeholder {
  font-family: Germania, sans-serif;
}
.gameCodeInput:-ms-input-placeholder {
  font-family: Germania, sans-serif;
}

@media only screen and (max-width: 540px) {
  input { 
      width: 300px;
  }
}