.container {
    background: #1B243A;
    height: 70vh;
    overflow-y: scroll;
    color: white;
    /*-ms-overflow-style: none;  /* IE and Edge */
    /*scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */


/* width */

.container::-webkit-scrollbar {
    width: 10px;
}


/* Track */

.container::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}


/* Handle */

.container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 540px) {
    .container {
        margin: 0 10px;
        margin-top: 0;
    }
}