.moneys {
  color: white;
  padding-left: 20px;
  font-size: 20px;
}

.lilbanner {
  position: absolute;
  bottom: -16px;
}

.lilbannerImage {
  height: 70px;
}

.monies {
  height: 20px;
  margin-right: 5px;
}

.sheild {
  height: 40px;
  position: absolute;
  right: -25px;
  z-index: 10;
  filter:drop-shadow(0px 0px 5px rgba(0,0,0,0.15))

}

.rebellion {
  color:#eb304e;
  cursor:pointer;
  margin-left: 10px;
}

.nameLabel {
  color: white;
  font-size: 28px;
  padding-left: 20px;
  top: 6px;
  position: absolute;
  width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.headerThing {
  height: 114px;
  margin-top: 10px;
  display: -webkit-box; 
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
img {    max-height: 114px; width: auto;}
}

.sideWrap {
  position: relative;
}

.regionLabel {
  color: black;
  background-color: white;
  width: 200px;
  padding-left: 20px;
  position: absolute;
  top: 36px;
}

.currentScheme {
  padding: 10px 0;
  color: white;
  vertical-align: middle;
}

.scheme {
  padding-left: 15px;  
  padding-right: 5px;
  cursor: pointer;
}

.questionMark {
  background-color: #8497b2;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  display: inline-block;
  padding-left: 6.7px;
  line-height: 21px;
  cursor: pointer;
}

@media only screen and (max-width: 540px) {
  .moneys {
    // padding-left: 20px;
    font-size: 12px;
  }
  
  .lilbanner {
    position: absolute;
    bottom: -11px;
  }
  
  .lilbannerImage {
    height: 48px;
  }
  
  .monies {
    height: 14px;
  }
  
  .sheild {
    height: 20px;
    position: absolute;
    right: 0px;
  }
  
  .nameLabel {
    font-size: 16px;
    padding-left: 10px;
    position: absolute;
    width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .headerThing {
    margin: 0 10px;
    margin-top: 20px;
    height: 70px;
  }
  
  .regionLabel {
    width: 130px;
    padding-left: 10px;
    position: absolute;
    top: 19px;
    font-size: 10px;
  }
  
  .currentScheme {
    margin-left: 10px;
    font-size: 14px;
  }
  
  .scheme {
    padding-right: 10px;
    padding-left: 7px;
  }

}