/* empties for now */
.voteText {
  margin-left: 25px;
  padding: 10px;
  padding-left: 50px;
  font-size: 20px;
  min-height: 70px;
  display: flex;
  // I COMMENTED display: flex :/ add it somewhere else if its causing problems, otherwise remove it
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &[disabled] {
    background-color: #838e8f;
    color: rgba(255, 255, 255, 0.6);
    cursor: default;
  }
}

.voteText:hover {
  color: rgba(255, 255, 255, 0.4);

  &[disabled] {
    color: rgba(255, 255, 255, 0.6);
  }
}

.choice {
  margin: 0 30px;
  position: relative;
  margin-bottom: 20px;
  transition: transform .05s;
}

.choice:active {
  transform: scale(1.05);

  &[disabled] {
    transform: unset;
  }
}

.lilbox {
  height: 50px;
  width: 50px;
  // background-color: #375355;
  position: absolute;
  transform: rotate(45deg);
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  &[disabled] {
    color: rgba(255, 255, 255, 0.6);
    cursor: default;
  }
}

.lockedIcon {
  height: 30px;
}

.choiceLetter {
  font-size: 30px;
  position: absolute;
  transform: rotate(-45deg);
}

.choiceDetail {
  padding-left: 80px;
  font-style: italic;
  color: #86929a;
}

@media only screen and (max-width: 540px) {
  .voteText {
    margin-left: 15px;
    padding: 5px;
    padding-left: 30px;
    font-size: 14px;
    min-height: 55px;
  }

  .choice {
    margin: 0 20px;
    margin-bottom: 15px;
  }

  .lilbox {
    height: 30px;
    width: 30px;
    top: 5px;
  }

  .choiceLetter {
    font-size: 20px;
  }

  .choiceDetail {
    padding-left: 35px;
    font-size: 12px;
  }
}
