

.container {
    text-align: center;
    color:white;
    margin-top: 100px;
    //override the existing min height
    min-height: 0; 
}

.infoMessage {
    margin: 20px 0;
}

@media only screen and (max-width: 540px) {
  input { 
      width: 300px;
  }
}