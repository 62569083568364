.header{
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
}

.waiting {
    color: white;
    text-align: center;
    font-size: 14px;
    opacity: 0.5;
    padding: 0 15px;
}

.wrapper {
    padding-top: 30%;
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 20px
    }
}

.button {
    background-color: rgb(17, 164, 154);
    color: white;
    width: 200px;
    text-align: center;
    padding: 10px;
    bottom: 50px;
    cursor: pointer;
    margin: 50px auto;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.buttonWrap{
    width:100%;
    display: flex;
}

.rebellion {
    color: #eb304e;
}

.nobleJoinTeamContainer{
    margin-top: 10vh;
    color: white;
}