.lobbyCode {
  margin-top: 10px;
  color: white;
}

.lobbyCodeTitle {
  display: inline-block;
  line-height: 33px;
  margin-left: 20px;
}

.lobbyCodeValue {
  font-size: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 50px;
  margin-right: 15px;
}

.codeBanner {
  position: absolute;
  height: 33px;
  left: 0;
  z-index: -1;
}
.quitButton {
  text-transform: uppercase;
  width: 108px;
  text-align: center;
  height: 30px;
  background: #5a769a;
  line-height: 30px;
  margin-top: -7px;
  cursor: pointer;
}

.quitButton.center{
  margin-top:10px;
  display:block;
  margin-left:auto;
  margin-right:auto;
}

@media only screen and (max-width: 540px) {
  .quitButton {
    height: 20px;
    width: 60px;
    text-align: center;
    display: inline;
    float: right;
    margin-top:1px;
    line-height: 20px;
  }
  
  .lobbyCode {
    font-size: 10px;
    margin-top: 10px;
    position: relative;
  }

  .lobbyCodeTitle {
    display: inline-block;
    line-height: initial;
    margin-left: 12px;
  }

  .codeBanner {
    height: 20px;
    top: 1px;
  }

  .lobbyCodeValue {
    font-size: initial;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
    margin-right: 15px;
  }
}
