.flag {
    // background-image: url("./IMAGE-StandardButton.png");
    position: relative;
    background: none !important;
    height: 50px;
    font-size: 24px;
    color: white;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 45px;
    line-height: 42px;
    min-width: 236px;
    .flagImg {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;    
        width: 100%;
        height: 100%;
    }
    &:disabled {
        opacity: .3;
        &:hover {
            opacity: .3;
        }
    }
    &:hover {
        opacity: 1;
    }
}

@media only screen and (max-width: 540px) {
    .flag {
        font-size: 16px;
        height: 34px;
        line-height: 30px;
    }
}