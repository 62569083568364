.topNav {    
    margin-top:18vh;    
    background: #fff;
}

.topNav img {
    height: 14vh;
    margin: 0 auto;
    display:block;
    background-image: url("./pattern1c.png");
    background-repeat: repeat;
    background-size: 26vh;
}

@media only screen and (max-width: 540px) {
    .topNav {
        margin-top: 9vh;
    }
}