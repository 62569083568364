.header{
    padding-top: 50px;
    text-align: center;
    font-size: 40px;
}

/* copied into somewhere else, maybe we can make a component out of it */
.information{
    color: rgb(165, 185, 212);
    font-style: italic;
    padding: 10px 0;
    text-align: center;
    font-size: 20px;
}

.separator {
    margin-top: 25px;
    margin-bottom: 50px;
}

.itemBlock {
    margin: 0 25px;
    position: relative;
    margin-bottom: 40px;
}

.itemEmblem {
    height: 85px;
    position: absolute;
    top: -12.5px;
    z-index: 99;
}

.itemHeader {
    background-color: white;
    display: inline-block;
    letter-spacing: 2px;
    position: absolute;
    top: -12.5px;
    padding-right: 20px;
    padding-left: 80px;
    height: 25px;
    line-height: 25px; // to put the text in the middle vertically
}

.pointyRightHeader {
    position: absolute;
    background-color: white;
    height: calc(100% * 0.71);
    width: calc(25px * 0.71);
    transform: rotate(45deg);
    top: calc(100% * 0.146);
    right: calc(-25px * 0.353);
}

.itemValue {
    padding: 10px;
    padding-left: 80px;
    font-size: 25px;
    margin-bottom: 30px;
    margin-right: 50px;
    padding-top: 20px;
    height: 60px;
    line-height: 30px;
}

.pointyRightValue {
    position: absolute;
    height: calc(60px * 0.71);
    width: calc(60px * 0.71);
    transform: scaleX(1.5) rotate(45deg);
    top: calc(60px * 0.146);
    right: calc(-60px * 0.353 + 50px);
}