/* empties for now */

.header {
    font-size: 40px;
    padding-top: 20px;
    text-align: center;
}

.wealth {
    text-align: center;
    color: #86929a;
    padding: 10px 0;
}

.block {
    margin: 10px 50px;
    position: relative;
    padding-bottom: 7px;
}

.emblem {
    position: absolute;
    left: -10px;
    height: 75px;
    width: 50px;
    img {
        width: 100%;
    }
    filter:drop-shadow(0px 0px 3px rgba(0,0,0,0.2))
}

.title {
    // background-color: #ad52e1;
    text-align: center;
    height: 25px;
}

.subblock {
    height: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0 20px;
    margin: 0 7px;
    margin-top: 7px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}
@media only screen and (max-width: 540px) {
    .subblock {
        height: 50px;
    }
}

.subblock:hover {
    background-color: rgba(255, 255, 255, 0.2);
    .pledgeAmount {
        transform: scale(1.15);
    }
}

.subblock:active {
    .pledgeAmount {
        transform: scale(1);
    }
}

.subblock > div {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.icon {
    img{
        width: 100%;
        padding: 0 20px;
        object-fit: contain;
    }
}

.name {
    text-align: center;
    font-size: 14px;
    margin: 0 10px;
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
}

.bldgNameBg {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.stat {
    font-size: 14px;
    text-align: center;
    font-style: italic;
}

.pledgeAmount {
    font-size: 20px;
    margin: 0 10px;
    height: 30px;
    position: relative;
    z-index: 10;
    transition: transform .05s;
    div {
        display: inline;
    }
}

.backButton {
    background-color: rgb(17, 164, 154);
    width: 200px;
    text-align: center;
    padding: 10px;
    bottom: 50px;
    cursor: pointer;
    margin: 50px auto;
}

.disabledPledge {
    opacity: 0.5;
}

.moneyIcon {
    display: inline;
    padding: 5px;
    height: 100%;
}

.pledgeBackgroundImg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    left: 0;
}

@media only screen and (max-width: 540px) {
    .header {
        font-size: 24px;
    }

    .information {
        font-size: 16px;
    }

    .block {
        margin: 10px 20px;
    }

    .emblem {
        width: 30px;
        left: -5px;
    }

    .title{
        font-size: 12px;
        height: 20px;
        line-height: 20px;
    }

    .name {
        font-size: 10px;
        padding: 0 10px;
    }

    .stat {
        font-size: 10px;
    }

    .subblock {
        padding: 0;
    }

    .subblock > div {
        font-size: 10px;
    }

    .subblock>div:nth-child(2) {
        // flex: 1;
        font-size: 10px;
    }
    
    .pledgeAmount {
        font-size: 10px;
        height: 20px;
        line-height: 20px;
        // margin: 0;
        // margin-left: 10px;
    }

    .icon {
        align-items: center;
        img{
            padding: 10px;
            max-width: 100px;
        }
    }

    @media only screen and (max-width: 540px) {
        .icon {
            img{
                padding: 0;
                margin-bottom: 5px;
                max-width: 50px;
            }            
        }
        .wealth {
            padding: 0px 0 0 0;
        }
        .header {
            padding-top: 5px;
        }
        .block {
            margin: 7px 50px;
            position: relative;
            padding-bottom: 7px;
        }
    }
}