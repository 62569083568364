#root {
  font-family: Germania, Veranda, sans-serif;
  overflow: hidden;
}

input {
  width: 100%;
  height: 50px;
  background-color: #49546a;
  border-radius: 8px;
  border: none;
  font-size: 20px;
  padding: 0 20px;
  color: white;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

hr {
  width: 80%;
  height: 3px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.errors {
  color: red;
}

button {
  background: #314253;
  color: #909ba0;
  &:disabled {
    background: #28333d;
  }
}
.rebelion-notice{text-align: center;
  color: red;}
.counts-accent-bg {
  background-color: #AD52E1
}
.counts-bg {
  background-color: #5F3079;
  &[disabled] { 
    background-color: #5F3079 !important;
  }
}
.counts-color {
  color: #653e98;
}

.barons-accent-bg {
    background-color:#600e1a;
}
.barons-bg {
  background-color:  #b9172f;
  &[disabled] {
    background-color: #b9172f !important;
  }
}
.barons-color {
    color: #b9172f;
}

.patricians-accent-bg {
  background-color: #14543b;
}
.patricians-bg {
  background-color: #17a16a;
  &[disabled] {
    background-color: #17a16a !important;
  }
}
.patricians-color {
    color: #17a16a;
}

.grandees-accent-bg {
  background-color: #D9B629;
}
.grandees-bg {
  background-color: #9E8123;
  &[disabled] {
    background-color: #9E8123 !important;
  }
}
.grandees-color {
    color: #9E8123;
}

.chiefs-accent-bg {
  background-color: #21A3E5;
}
.chiefs-bg {
  background-color: #3269B7;
  &[disabled] {
    background-color: #3269B7 !important;
  }
}
.chiefs-color {
    color: #3269B7;
}
.basic-accent-bg {
  background-color: #375355;
}
.basic-bg {
  background-color: #5C999F;
}
.basic-color {
  color: #5C999F;
}