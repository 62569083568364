.container {
    color: white;
    padding: 20px 0;
}

.headerTitle {
    padding: 10px 0;
    font-size: 40px;
    text-align: center;
}

@media only screen and (max-width: 540px) {
    .headerTitle {
        font-size: 24px;
    }
}